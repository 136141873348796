<script>
export default {
  name: 'TenantTable',
  props: {
    headers: Array,
    rows: Array,
    title: String,
    totalRows: Number,
  },
  computed: {
    columns() {
      return this.headers.map((header) => header.header);
    },
    filteredData() {
      let result;
      if (this.filterValue) {
        const regex = new RegExp(this.filterValue, 'i');
        result = this.data.filter((item) => {
          return item.join('|').search(regex) >= 0;
        });
      } else {
        result = this.data;
      }

      return result.slice(this.start, this.start + this.pageSize);
    },
    data() {
      return this.rows.map((row) => ({
        data: [
          row.TenantName,
          row.Admin,
          row.BusinessUnit,
          row.BROWSER,
          row.API,
          row.HTTP,
        ],
      }));
    },
  },
};
</script>

<template>
  <cv-data-table
    :columns="columns"
    :title="title"
    :pagination="{ numberOfItems: this.totalRows }"
    @pagination="$emit('pagination', $event)"
    sortable
    @sort="$emit('sort', $event)"
  >
    <template slot="data">
      <cv-data-table-row v-for="(row, rowIndex) in data" :key="`${rowIndex}`">
        <cv-data-table-cell
          v-for="(cell, cellIndex) in row.data"
          :key="`${cellIndex}`"
          >{{ cell }}</cv-data-table-cell
        >
        <!-- <template slot="expandedContent">
          {{ row.description }}
        </template> -->
      </cv-data-table-row>
    </template>
  </cv-data-table>
</template>
