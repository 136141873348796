<template>
  <div id="app">
    <side-navigation />
    <cv-content id="#main-content">
      <router-view />
    </cv-content>
  </div>
</template>

<script>
import SideNavigation from './components/SideNavigation';
export default {
  name: 'App',
  components: {
    SideNavigation,
  },
};
</script>

<style lang="scss">
@import './styles/carbon';
</style>
