import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/updateTenant',
      name: 'update-tenant',
      // route level code-splitting
      // this generates a separate chunk (repo-page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "repo-page" */ './views/UpdateTenant'),
    },
    {
      path: '/clusterDetails',
      name: 'cluster-details',
      // route level code-splitting
      // this generates a separate chunk (repo-page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Dashboard,
      component: () =>
        import(/* webpackChunkName: "repo-page" */ './views/ClusterDetails'),
    },
  ],
});
