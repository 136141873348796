import Vue from 'vue';
import App from './App.vue';
import router from './router';
window.axios = require('axios');

import CarbonComponentsVue from '@carbon/vue';
Vue.use(CarbonComponentsVue);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
