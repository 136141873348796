const { Buffer } = require('buffer');
export async function getAllTenants() {
  // const username = process.env.USERNAME;
  // const password = process.env.PASSWORD;
  const apiService =
    'https://synthetics-monitoring-ops-b4bfc7d6da7af1aceb80d1c4309fd8b2-0000.eu-de.containers.appdomain.cloud';

  // Create the Authorization header
  // const authorizationHeader = `Basic ${Buffer.from(
  //   `${username}:${password}`
  // ).toString('base64')}`;
  axios.defaults.headers.get['content-type'] = 'application/json';
  // axios.defaults.headers.get['access-control-allow-region'] = '*';
  const response = await axios.get(
    `${apiService}/v1/api/tenants/all/executionreport`
  );
  let jsonResponse = [];
  response.data['Value'].forEach((element) => {
    var jsonData = {};
    jsonData['TenantName'] = element['name'];
    jsonData['Admin'] = element['Admin'];
    jsonData['BusinessUnit'] = element['BusinessUnit'];
    jsonData['BROWSER'] =
      element['aggregations']['pops']['buckets'][2]['doc_count'];
    jsonData['API'] =
      element['aggregations']['pops']['buckets'][1]['doc_count'];
    jsonData['HTTP'] =
      element['aggregations']['pops']['buckets'][0]['doc_count'];
    jsonResponse.push(jsonData);
  });
  //   console.log('data length is : ', jsonResponse);
  return jsonResponse;
}

// export async function createUser(data) {
//   const response = await axios.post(`/api/user`, { user: data });
//   return response.data;
// }
