<template>
  <div class="cds--grid cds--grid--full-width cds--grid--no-gutter repo-page">
    <div class="cds--row repo-page__r1">
      <div class="cds--col-lg-16">
        <div class="d-sm-flex align-items-center justify-content-between mb-8">
          <h3 class="mb-0 text-gray-800">Dashboard</h3>
          <cv-button @click="generateExcelReport" variant="primary">
            Generate Report
          </cv-button>
        </div>
        <hourly-report :hourly="hourly" />
        <tenant-table
          :headers="headers"
          :rows="pagedRows"
          :totalRows="rows.length"
          @pagination="onPagination"
          @sort="onSort"
          title="Tenants"
        />
      </div>
      <!-- :options="options" -->
    </div>
    <br />
    <!-- <update-tenant></update-tenant> -->
  </div>
</template>
<!-- :rows="getAllTenants()" -->
<script>
import TenantTable from './TenantTable';
import HourlyReport from './HourlyReport';
import { getAllTenants } from '../../services/AllTenants';
import { getAggregationReport } from '../../services/AggregationReport';

import Papa from 'papaparse';
const headers = [
  {
    key: 'TenantName',
    header: 'TenantName',
  },
  {
    key: 'Admin',
    header: 'Admin',
  },
  {
    key: 'BusinessUnit',
    header: 'BusinessUnit',
  },
  {
    key: 'BROWSER',
    header: 'BROWSER',
  },
  {
    key: 'API',
    header: 'API',
  },
  {
    key: 'HTTP',
    header: 'HTTP',
  },
];

export default {
  name: 'Dashboard',
  components: { TenantTable, HourlyReport },
  data() {
    return {
      headers,
      rows: [],
      hourly: {},
      pageSize: 0,
      pageStart: 0,
      page: 0,
    };
  },
  computed: {
    pagedRows() {
      return this.rows.slice(this.pageStart, this.pageStart + this.pageSize);
    },
  },
  methods: {
    getAllTenants() {
      getAllTenants().then((response) => {
        this.rows = response;
      });
    },
    getAggregationReport() {
      getAggregationReport().then((response) => {
        this.hourly = response;
      });
    },
    onPagination(val) {
      this.pageSize = val.length;
      this.pageStart = val.start;
      this.page = val.page;
    },
    generateExcelReport() {
      let filename = 'allTenants.csv';
      let text = Papa.unparse(this.rows);

      let element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    onSort(sortBy) {
      if (sortBy) {
        // console.log('data is : ', this.rows);
        // console.log('sort values are ', sortBy.index, sortBy.order);
        this.rows.sort((a, b) => {
          const aArray = Object.values(a);
          const bArray = Object.values(b);
          let itemA = aArray[sortBy.index];
          let itemB = bArray[sortBy.index];
          // console.log(' sort by value is', itemA, aArray[sortBy.index]);
          // console.log(' sort by value is', itemB, bArray[sortBy.index]);
          if (sortBy.order === 'descending') {
            if (sortBy.index == 3 || sortBy.index == 4 || sortBy.index == 5) {
              // sort as number
              // console.log('sorting as numbers');
              return parseFloat(itemA) - parseFloat(itemB);
            } else {
              const _a = itemA.toString();
              const _b = itemB.toString();
              return _b.localeCompare(_a);
            }
          }
          if (sortBy.order === 'ascending') {
            // console.log('came here');
            if (sortBy.index == 3 || sortBy.index == 4 || sortBy.index == 5) {
              // sort as number
              // console.log('sorting as numbers');
              return parseFloat(itemB) - parseFloat(itemA);
            } else {
              const _a = itemA.toString();
              const _b = itemB.toString();
              return _a.localeCompare(_b);
            }
          }
          return 0;
        });
      }
    },
  },
  mounted() {
    // this.options.onReload();
    this.getAllTenants();
    this.getAggregationReport();
  },
};
</script>

<style lang="scss">
@import '../../styles/carbon-utils';
@import '../../styles/carbon-extra';
</style>
