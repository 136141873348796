const { Buffer } = require('buffer');
export async function getAggregationReport() {
  // const username = process.env.USERNAME;
  // const password = process.env.PASSWORD
  const apiService =
    'https://synthetics-monitoring-ops-b4bfc7d6da7af1aceb80d1c4309fd8b2-0000.eu-de.containers.appdomain.cloud';

  // Create the Authorization header
  // const authorizationHeader = `Basic ${Buffer.from(
  //   `${username}:${password}`
  // ).toString('base64')}`;
  // console.log('api service is : ', apiService);
  axios.defaults.headers.get['content-type'] = 'application/json';
  const response = await axios.get(`${apiService}/v1/api/tenants/aggregation`);
  let jsonResponse = {};
  jsonResponse['TotalExecution'] = response.data['Total'];
  jsonResponse['HTTP'] = response.data['Http'];
  jsonResponse['API'] = response.data['Api'];
  jsonResponse['BROWSER'] = response.data['Browser'];
  //   console.log('data length is : ', jsonResponse);
  return jsonResponse;
}
