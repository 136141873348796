<template>
  <cv-header aria-label="Carbon tutorial">
    <cv-header-name to="/" prefix="Tenant">Dashboard</cv-header-name>

    <cv-header-nav>
      <cv-header-menu-item to="/updateTenant"
        >Update Tenant</cv-header-menu-item
      >
    </cv-header-nav>

    <cv-header-nav>
      <cv-header-menu-item to="/clusterDetails"
        >Cluster Details</cv-header-menu-item
      >
    </cv-header-nav>
  </cv-header>
</template>

<script>
export default {
  name: 'SideNavigation',
};
</script>
