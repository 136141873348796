<template>
  <div class="row" :hourly="hourlyReport">
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
                TotalExecution (Hourly)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ this.hourly.TotalExecution }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                HTTP (Hourly)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ this.hourly.HTTP }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                API (Hourly)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ this.hourly.API }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-success text-uppercase mb-1"
              >
                BROWSER (Hourly)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ this.hourly.BROWSER }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Json16 } from '@carbon/icons-vue';

export default {
  name: 'HourlyReport',
  props: {
    hourly: Json16,
  },
  computed: {
    hourlyReport() {
      return this.hourly;
    },
  },
};
</script>
